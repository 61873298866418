import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { loginURL } from '../proxy';

/**
 * Utility to check if token exists and is valid
 */
export const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token_link_card');
    if (typeof token === 'string' && token.trim() !== '') {
      try {
        const user = jwtDecode(token);
        return user?._id ? true : false;
      } catch (error) {
        console.error('JWT decode error:', error);
        return false;
      }
    }
    return false; // Token is not a valid string
  }
  return false; // Not in the browser
};

/**
 * Redirect user if not authenticated, to prevent multiple redirects
 * @param {string} redirectURL - The URL to redirect to if the user is not authenticated
 */
export const handleAuthentication = (redirectURL) => {
  if (!isAuthenticated()) {
    if (window.location.href !== redirectURL) {
      window.location.href = redirectURL;
    }
  }
};

/**
 * Example usage in a component or route
 * This function ensures that the user is either redirected once, or allowed access.
 */
const PrivateRoute = ({ element }) => {
  useEffect(() => {
    // Perform authentication check and handle redirect
    handleAuthentication(loginURL);
  }, []); // Runs once on component mount or when loginURL changes

  return isAuthenticated() ? element : null; // Renders element if authenticated
};

export default PrivateRoute;
