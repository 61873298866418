import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
// import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Client from "../../components/client";
import Footer from "../../components/footer";
import AboutUs from "../../components/home/AboutUs";
import OurMission from "../../components/home/OurMission";
import OurVision from "../../components/home/OurVision";
import Navbar from "../../components/navbar";
import ScrollToTop from "../../components/scroll-to-top";
import Switcher from "../../components/switcher";
import Tagline from "../../components/tagline";
import { useAppContext } from "../../context/AppContext";

export default function IndexMain() {
    // const [store.state.lang, setSelectedLanguage] = useState('en');
    const store = useAppContext();

    // useEffect(() => {
    //     const getSwitchedLang = localStorage.getItem('switchLanguage');
    //     if (getSwitchedLang) {
    //         setSelectedLanguage(getSwitchedLang);
    //     }
    // }, []);

    // const user = store?.state?.user
    // console.log(store.setSta);
    console.log('object', store?.state);
    return (
        <>
            <Tagline />
            <Navbar navClass="defaultscroll is-sticky tagline-height" />
            <section className="swiper-slider-hero relative block h-screen" id="home">
                <div className="swiper-container absolute end-0 top-0 w-full h-full">
                    <Carousel
                        className="swiper-wrapper h-full"
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={3000}
                        transitionTime={1500}
                    >
                        <div className="swiper-slide flex items-center overflow-hidden h-full" dir={store.state.lang === 'en' ? "ltr" : "rtl"}>
                            <div className={`slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-red-600/5 flex items-center bg-[url('../../assets/images/hero/hero-0.png')] bg-no-repeat bg-cover;`} style={{ backgroundPosition: `top ${store.state.lang === 'en' ? 'right' : 'left'}` }}>
                                <div className="container relative">
                                    <div className="grid md:grid-cols-2 grid-cols-1 text-start">
                                        <div>
                                            <span className="uppercase font-semibold text-lg">
                                                New Collection
                                            </span>
                                            <h4 className="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3">
                                                The Gift Suite
                                            </h4>
                                            <p className="text-lg">
                                                Our latest collection of essential basics.
                                            </p>

                                            <div className="mt-6">
                                                <Link
                                                    to=""
                                                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-slate-900 dark:bg-green-500 text-white rounded-md"
                                                >
                                                    Shop Now <i className="mdi mdi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-slide flex items-center overflow-hidden" dir={store.state.lang === 'en' ? "ltr" : "rtl"}>
                            <div className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image bg-blue-600/5 flex items-center bg-[url('../../assets/images/hero/hero-1.png')]  bg-no-repeat bg-cover;" style={{ backgroundPosition: `top ${store.state.lang === 'en' ? 'right' : 'left'}` }}>
                                <div className="container relative">
                                    <div className="grid md:grid-cols-2 grid-cols-1 text-start">
                                        <div>
                                            <span className="uppercase font-semibold text-lg">
                                                Christmas Sale 2023
                                            </span>
                                            <h4 className="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3">
                                                End of Season Sale
                                            </h4>
                                            <p className="text-lg">
                                                Our latest collection of essential basics.
                                            </p>

                                            <div className="mt-6">
                                                <Link
                                                    to=""
                                                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-slate-900 dark:bg-green-500 text-white rounded-md"
                                                >
                                                    View Collection{" "}
                                                    <i className="mdi mdi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>

                    <div className="swiper-pagination"></div>
                </div>
            </section>

            <section className="relative md:py-24 py-16 space-y-[120px]">
                <AboutUs />
                <OurMission />
                <OurVision />
                <Client />
            </section>
            <Footer />
            <Switcher />
            <ScrollToTop />
        </>
    );
}
