import React from "react";

import useGetApi from "../api/useGetApi";
import ShopCard from "../pages/shop/shop-grid/shop-card";

export default function ArrivalItem() {
  const { data, isLoading } = useGetApi({
    url: `/products/public`,
  });

  return (
    <div className="container lg:mt-24 mt-16">
      <div className="grid grid-cols-1 mb-6 text-center">
        <h3 className="font-semibold text-3xl leading-normal">
          New Arrival Items
        </h3>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 pt-6">
        {data?.results?.slice(0, 4)?.map((item) => (
          <ShopCard key={item?._id} item={item} />
        ))}
      </div>
    </div>
  );
}
