import React, { useState } from "react";
import { Link } from "react-router-dom";

import Footer from "../../../components/footer";
import Navbar from "../../../components/navbar";
import ScrollToTop from "../../../components/scroll-to-top";
import Switcher from "../../../components/switcher";

import useGetApi from "../../../api/useGetApi";
import { FiChevronLeft, FiChevronRight } from "../../../assets/icons/vander";
import ShopCard from "./shop-card";

export default function ShopGrid() {
  const [query, setQuery] = useState({
    page: 1,
    limit: 100,
  });

  const { data, isLoading } = useGetApi({
    url: `/products/public?page=${query?.page}&limit=${query?.limit}`,
  });

  return (
    <>
      <Navbar navClass="defaultscroll is-sticky" />
      <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 mt-14">
            <h3 className="text-3xl leading-normal font-semibold">Shop</h3>
          </div>

          <div className="relative mt-3">
            <ul className="tracking-[0.5px] mb-0 inline-block">
              <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                <Link to="/">Cartzio</Link>
              </li>
              <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block uppercase text-[13px] font-bold text-orange-500"
                aria-current="page"
              >
                Shop{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-between items-center mb-6">
            <span className="font-semibold">
              Showing {data?.paginate?.currentPage}-{data?.paginate?.totalPage}{" "}
              of {data?.paginate?.totalCount} items
            </span>

            {/* <div className="md:flex items-center">
              <label className="font-semibold md:me-2">Sort by:</label>
              <select className="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                <option defaultValue="">Featured</option>
                <option defaultValue="">Sale</option>
                <option defaultValue="">Alfa A-Z</option>
                <option defaultValue="">Alfa Z-A</option>
                <option defaultValue="">Price Low-High</option>
                <option defaultValue="">Price High-Low</option>
              </select>
            </div> */}
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
            {data?.results?.map((item) => {
              return <ShopCard key={item?._id} item={item} />;
            })}
          </div>

          {data?.paginate?.currentLimit > 100 && (
            <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
              <div className="md:col-span-12 text-center">
                <nav aria-label="Page navigation example">
                  <ul className="inline-flex items-center -space-x-px">
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronLeft>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        1
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        2
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        aria-current="page"
                        className="z-10 size-[40px] inline-flex justify-center items-center text-white bg-green-500 border border-orange-500"
                      >
                        3
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        4
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        5
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-green-500 dark:hover:border-green-500 hover:bg-green-500 dark:hover:bg-orange-500"
                      >
                        <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronRight>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
      <Switcher />
      <ScrollToTop />
    </>
  );
}
