import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ totalPage, currentPage, onPageChange }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const calculatePageRange = () => {
      let start = Math.max(1, currentPage - 2);
      let end = Math.min(totalPage, start + 4);

      if (end - start < 4) {
        start = Math.max(1, end - 4);
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    setPages(calculatePageRange());
  }, [currentPage, totalPage]);

  const handlePageChange = (page) => {
    if (page !== currentPage && page >= 1 && page <= totalPage) {
      onPageChange(page);
    }
  };

  if (totalPage <= 1) return null;

  return (
    <nav aria-label="Page navigation">
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1" />
          </button>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <button
              onClick={() => handlePageChange(page)}
              aria-current={page === currentPage ? 'page' : undefined}
              className={`size-[40px] inline-flex justify-center items-center ${
                page === currentPage
                  ? 'text-white bg-orange-500 border border-orange-500'
                  : 'text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500'
              }`}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPage}
            className={`size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
              currentPage === totalPage ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;