import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrivalItem from "../../../components/arrival-item";
import Footer from "../../../components/footer";
import Navbar from "../../../components/navbar";
import ProductDetail from "../../../components/product-detail";
import ScrollToTop from "../../../components/scroll-to-top";
import Switcher from "../../../components/switcher";
import Tagline from "../../../components/tagline";

import image2 from "../../../assets/images/shop/mens-jecket-3.jpg";
import image5 from "../../../assets/images/shop/mens-jecket-4.jpg";
import image4 from "../../../assets/images/shop/mens-jecket-back.jpg";
import image3 from "../../../assets/images/shop/mens-jecket-left.jpg";
import image1 from "../../../assets/images/shop/mens-jecket.jpg";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import useGetApi from "../../../api/useGetApi";
import PageTitle from "../../../components/common/page-title";
import { useAppContext } from "../../../context/AppContext";

export default function ProductDetails() {
  const { id } = useParams();
  const {
    state: { lang },
  } = useAppContext();
  const { data, isLoading } = useGetApi({ url: `/products/${id}` });

  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const images = [image1, image2, image3, image4, image5];
  const handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  return (
    <>
      <Tagline />
      <Navbar navClass="defaultscroll is-sticky tagline-height" />
      <PageTitle
        title={data?.title?.[lang]}
        breadcrumb="Store"
        breadcrumb2={data?.title?.[lang]}
      />

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
            <div className="lg:col-span-5">
              <div className="grid md:grid-cols-12 gap-3">
                <div className="md:col-span-12">
                  <Link
                    to="#"
                    onClick={() => handleCLick(0)}
                    className="lightbox duration-500 group-hover:scale-105"
                    title=""
                  >
                    <img
                      src={data?.coverImgUrl}
                      className="shadow dark:shadow-gray-700"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7">
              <ProductDetail data={data} />
            </div>
          </div>

          {/* <ProductAboutTab /> */}
        </div>

        <ArrivalItem />
      </section>
      <Footer />
      <Switcher />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <ScrollToTop />
    </>
  );
}
