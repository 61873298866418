import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { customToast } from "../../utils/customToast";
import { fetchPaymentSession, webHooksCalledPayment } from "../../utils/instance";
import BackToHome from "../back-to-home";
import Switcher from "../switcher";

export default function SuccessPayment() {


  const handleCopy = (sessionId) => {
    navigator.clipboard.writeText(sessionId);
    customToast("Session ID copied to clipboard!", "success");
  };
  const [isReq, setIsReq] = useState(true)

  const [paymentInfo, setPaymentInfo] = useState({})
  const fetchPayments = async (sessionId) => {
    setIsReq(true)
    const res = await fetchPaymentSession(sessionId)
    if (res?.data?.data?.sessionId) {
      await webHooksCalledPayment(res?.data?.data)
    }
    setIsReq(false)
    setPaymentInfo(res?.data?.data)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const sessionId = localStorage.getItem("sessionId")
        if (sessionId) {
          fetchPayments(sessionId)
        }
      } catch (error) {
        setIsReq(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReturnToHome = () => {
    try {
      localStorage.removeItem("sessionId")
      window.open('/', "_self")
    } catch (error) {

    }
  }

  const getStatusStyle = (status) => {
    switch (status) {
      case 'paid':
        return {
          icon: 'mdi-check-circle',
          bgColor: 'bg-emerald-600',
        };
      case 'unpaid':
        return {
          icon: 'mdi-alert-circle',
          bgColor: 'bg-yellow-600',
        };
      case 'expired':
        return {
          icon: 'mdi-close-circle',
          bgColor: 'bg-red-600',
        };
      case 'cancelled':
        return {
          icon: 'mdi-cancel',
          bgColor: 'bg-gray-400',
        };
      default:
        return {
          icon: 'mdi-information',
          bgColor: 'bg-gray-600',
        };
    }
  };
  
  const { icon, bgColor } = getStatusStyle(paymentInfo?.payment_status);

  // console.log(paymentInfo, 'paymentInfo')
  return (
    <>

      {
        isReq && <div className="my-28">
          <div role="status" >
            <svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <p className="text-center"> Loading Payment</p>
        </div>
      }
      {!isReq && paymentInfo?.sessionId && <section className="relative h-screen flex justify-center items-center bg-slate-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-2/5">
              <div className="relative overflow-hidden rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
                <div className={`px-6 py-12 ${bgColor} text-center`}>
                  <i className={`mdi ${icon} text-white text-6xl`}></i>
                  <h5 className="text-white text-xl tracking-wide font-semibold mt-2 uppercase">
                    {paymentInfo?.payment_status}
                  </h5>
                </div>

                <div className="p-6 border-t border-gray-200 ">
                  <h3 className="text-lg font-medium text-gray-900">
                    Order Summary
                  </h3>
                  <div className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">Payment Time</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        {moment(paymentInfo?.createdAt).format('llll')}
                      </dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">Session ID</dt>
                      <dd
                        className="text-sm font-medium text-gray-900 relative"

                        onClick={() => handleCopy(paymentInfo?.sessionId)}
                        title="Click to copy"
                      >
                        {`${paymentInfo?.sessionId.slice(0, 25)}...`}
                        <span className="cursor-pointer text-blue-600 hover:text-blue-800 ml-2">
                          (Copy)
                        </span>
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">Subtotal</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        (OMR) {parseFloat(paymentInfo?.orderSummary?.subTotal).toFixed(2)}
                      </dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">Tax</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        (OMR) {parseFloat(paymentInfo?.orderSummary?.tax).toFixed(2)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="text-base font-medium text-gray-900">
                        Total
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        (OMR) {parseFloat(paymentInfo?.orderSummary?.total).toFixed(2)}
                      </dd>
                    </div>
                  </div>
                  <div className="pt-4">
                    <button
                      onClick={() => handleReturnToHome()}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Return to Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
      <Switcher />
      <BackToHome />
    </>
  );
}
