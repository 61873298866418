import React, { useState } from "react";
import Footer from "../../../components/footer";
import Navbar from "../../../components/navbar";
import ScrollToTop from "../../../components/scroll-to-top";
import Switcher from "../../../components/switcher";

import useGetApi from "../../../api/useGetApi";
import {
  FiFacebook,
  FiInstagram,
  FiMusic,
  FiTwitter
} from "../../../assets/icons/vander";
import PageTitle from "../../../components/common/page-title";
import MessagePopup from "../../../components/messagePopup";
import Pagination from "../../../components/pagination";
import { getFullName } from "../../../utils";

// import { Facebook, Instagram, Twitter, Music } from 'lucide-react';
const noImg = "https://via.placeholder.com/150";

const SocialIcon = ({ Icon }) => (
    <Icon className="w-5 h-5 text-gray-500 hover:text-blue-500" />
);

const getSocialMedia = (key) => {
    switch (key) {
        case key === "facebook":
            return <SocialIcon Icon={FiFacebook} />;
        default:
            break;
    }
};

const AmbassadorCard = ({
    bannerBg,
    avatar,
    location,
    jobInformation,
    phone,
    firstName,
    lastName,
    //   address,
    //   socials,
    socialMediaLinks,
    _id,
}) => (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden text-center">
        <img
            src={bannerBg || noImg}
            alt={location?.country || "N/A"}
            className="w-full h-48 object-cover"
        />
        <div className="p-4 relative">
            <img
                src={avatar}
                alt={getFullName({ firstName,
                  lastName}) || "N/A"}
                className="w-12 h-12 object-contain mb-2 absolute top-[-1.5rem] -translate-x-1/2 left-1/2 border-2 border-white"
            />
            <br />
            <p className="text-sm text-green-600">{location?.country || "N/A"}</p>
            <h3 className="text-lg font-bold mb-1">
                {getFullName({ firstName,
    lastName}) || "---"}
            </h3>
            <p className="text-sm text-gray-600 mb-1">{phone?.fullNumber || "N/A"}</p>
            <p className="text-xs text-gray-500 mb-4">{`${location?.zipCode || ""}, ${location?.city || ""
                }, ${location?.state || ""}, ${location?.country || ""}`}</p>
            <div className="flex flex-col gap-4 items-center">
                <div className="flex space-x-2">
                    {/* {socialMediaLinks?.map} */}
                    {socialMediaLinks?.facebook ? (
                        <SocialIcon Icon={FiFacebook} />
                    ) : (
                        <SocialIcon Icon={FiFacebook} />
                    )}
                    {socialMediaLinks?.instagram ? (
                        <SocialIcon Icon={FiInstagram} />
                    ) : (
                        <SocialIcon Icon={FiInstagram} />
                    )}
                    {socialMediaLinks?.twitter ? (
                        <SocialIcon Icon={FiTwitter} />
                    ) : (
                        <SocialIcon Icon={FiTwitter} />
                    )}
                    {socialMediaLinks?.tiktok ? (
                        <SocialIcon Icon={FiMusic} />
                    ) : (
                        <SocialIcon Icon={FiMusic} />
                    )}
                </div>
                {/* <button className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">
          Send Message
        </button> */}
                <MessagePopup receiverId={_id} />
                {/* <Link to="/shop-cart" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md">Add to Cart</Link> */}
            </div>
        </div>
    </div>
);

export default function Agents() {
    const ambassadors = [
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "United States",
            company: "Acme Corporation",
            phone: "555-123-4567",
            address: "123 Main St, Anytown USA",
            socials: {
                facebook: "https://www.facebook.com/acme",
                twitter: "https://twitter.com/acme",
                instagram: "https://www.instagram.com/acme",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Canada",
            company: "Maple Leaf Inc.",
            phone: "555-987-6543",
            address: "456 Maple Ave, Toronto ON",
            socials: {
                facebook: "https://www.facebook.com/mapleleaf",
                twitter: "https://twitter.com/mapleleaf",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "United Kingdom",
            company: "British Industries Ltd.",
            phone: "020-7946-0123",
            address: "789 London Rd, London",
            socials: {},
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Australia",
            company: "Aussie Mates Pty Ltd",
            phone: "61-2-9876-5432",
            address: "10 Sydney St, Sydney NSW 2000",
            socials: {
                facebook: "https://www.facebook.com/aussiemates",
                instagram: "https://www.instagram.com/aussiemates",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Japan",
            company: "Sydney St, Sydney ",
            phone: "03-1234-5678",
            address: "10 Sydney St, Sydney NSW 2000",
            socials: {
                twitter: "https://twitter.com/tokyosangyok",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Germany",
            company: "Deutsche Qualität GmbH",
            phone: "030-12345678",
            address: "Bundesstraße 1, 10115 Berlin",
            socials: {
                facebook: "https://www.facebook.com/deutschequalitat",
                instagram: "https://www.instagram.com/deutschequalitat",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "France",
            company: "Entreprise Française S.A.",
            phone: "01-23-45-67-89",
            address: "1 Rue de Paris, 75001 Paris",
            socials: {},
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Spain",
            company: "Empresa Española S.L.",
            phone: "91-123-45-67",
            address: "Calle Mayor 1, 28001 Madrid",
            socials: {
                twitter: "https://twitter.com/empresaespanola",
                instagram: "https://www.instagram.com/empresaespanola",
            },
        },
        {
            image: "https://via.placeholder.com/150",
            logo: "https://via.placeholder.com/50",
            country: "Italy",
            company: "Azienda Italiana SpA",
            phone: "06-1234-5678",
            address: "Via del Corso 1, 00186 Roma",
            socials: {
                facebook: "https://www.facebook.com/aziendaitaliana",
                twitter: "https://twitter.com/aziendaitaliana",
                instagram: "https://www.instagram.com/aziendaitaliana",
            },
        },
    ];

    //   const [paginate, setPaginate] = useState()
    //   const { data, isLoading } = useGetApi({
    //     url: `/users?role=agent&limit=10&`,
    //   });


    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10);

    const { data, isLoading } = useGetApi({
        url: `/users?role=agent&limit=${limit}&page=${currentPage}`,
    });

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" />
            <PageTitle title={"Agents"} breadcrumb={"agents"} />
            {/* <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/hero/pages.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Blogs / News</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Cartzio</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Blogs</li>
                </ul>
            </div>
        </section> */}

            <section className="relative md:py-32 py-16">
                <div className="container relative">
                    <h2 className="text-2xl font-bold mb-6">Ambassadors</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {data?.results?.length > 0 ?
                            data?.results?.map((ambassador, index) => (
                                <AmbassadorCard key={index} {...ambassador} />
                            )) : <p>There is no agents available</p>}
                    </div>
                    {/* <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    {blogData.map((item,index)=>{
                        return(
                            <div className="group relative overflow-hidden shadow" key={index}>
                                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                    <img src={item.image} className="group-hover:scale-110 duration-500" alt=""/>
                                </div>

                                <div className="mt-6">
                                    <div className="flex mb-4">
                                        <span className="flex items-center text-slate-400 text-sm"><FiCalendar className="size-4 text-slate-900 dark:text-white me-1.5"></FiCalendar>{item.date}</span>
                                        <span className="flex items-center text-slate-400 text-sm ms-3"><FiClock className="size-4 text-slate-900 dark:text-white me-1.5"></FiClock>5 min read</span>
                                    </div>

                                    <Link to={`/blog-detail/${item.id}`} className="title text-lg font-semibold hover:text-orange-500 duration-500 ease-in-out">{item.title}</Link>
                                    <p className="text-slate-400 mt-2">{item.desc}</p>

                                    <div className="mt-3">
                                        <span className="text-slate-400">by <Link to="/" className="text-slate-900 dark:text-white hover:text-orange-500 dark:hover:text-orange-500 font-medium">Cartzio</Link></span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div> */}

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                        <div className="md:col-span-12 text-center">
                            {/* <nav aria-label="Page navigation example">
                                <ul className="inline-flex items-center -space-x-px">
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronLeft>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            1
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            2
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            aria-current="page"
                                            className="z-10 size-[40px] inline-flex justify-center items-center text-white bg-orange-500 border border-orange-500"
                                        >
                                            3
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            4
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            5
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                                        >
                                            <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronRight>
                                        </Link>
                                    </li>
                                </ul>
                            </nav> */}
                            <Pagination totalPage={data?.paginate?.totalPage}
                                currentPage={data?.paginate?.currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
            <ScrollToTop />
        </>
    );
}
