import React from "react";
import image from "../../assets/images/client/02.jpg";

const OurMission = () => {
  return (
    <div className="container grid grid-cols-2 gap-12 items-center justify-center">
      <div className="space-y-5">
        <h2 className="text-[32px] font-bold">Our Mission</h2>
        <div className="space-y-3">
          <p>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before the final copy is available. In
            publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="w-full">
        <img className="rounded mx-auto" src={image} alt="" />
      </div>
    </div>
  );
};

export default OurMission;
