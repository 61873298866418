const onInputValueChange = (prevState, key, value, subKey = null) => {
  if (subKey) {
    const nestedValue = prevState?.[key] || {};
    return {
      ...prevState,
      [key]: {
        ...nestedValue,
        [subKey]: value,
      },
    };
  } else {
    return {
      ...prevState,
      [key]: value,
    };
  }
};

const onCalculateSum = (products) => {
  return products?.reduce((sum, product) => {
    return sum + parseFloat(product?.price) * product?.quantity;
  }, 0);
};

const onCalculateTaxes = (subTotal, taxRate = 5) => {
  const taxes = subTotal * (taxRate / 100);
  return taxes.toFixed(2);
};

const getFullName =(user)=>{
  return `${user?.firstName||"--"} ${user?.lastName||"--"}`
}
export { onCalculateSum, onCalculateTaxes, onInputValueChange,getFullName };
