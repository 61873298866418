import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import useGetApi from "../api/useGetApi";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    lang: "en",
    addToCart: [],
    token: "",
    user: null,
  });

  const [userId, setUserId] = useState("");

  const { data: userData } = useGetApi({
    url: userId && `users/profile/${userId}`,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      user: userData,
    }));
  }, [userData]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const lang = localStorage.getItem("lang") || "en";
      let savedCart = [];
      try {
        savedCart= JSON.parse(localStorage.getItem("addToCart"))
      } catch (error) {
        savedCart=[]
      }
      const token = localStorage.getItem("token_link_card") || "";
      const user = token ? jwtDecode(token) : {};

      setUserId(user?._id);
      setState((prev) => ({
        ...prev,
        addToCart: savedCart,
        lang,
        token,
      }));
    }
  }, []);

  const onLanguageChange = (value) => {
    setState((prev) => ({
      ...prev,
      lang: value,
    }));
  };

  const onAddToCart = (item) => {
    if (!item) return;

    const data = {
      id: item?._id,
      title: item?.title,
      description: {
        en: item?.description?.en?.slice(0, 15),
        ar: item?.description?.ar?.slice(0, 15),
      },
      price: item?.price,
      image: item?.coverImgUrl,
      quantity: 1,
    };

    const alreadyAdded = state?.addToCart?.some((d) => d?.id === data?.id);

    if (alreadyAdded) {
      //! quantity need to fix
      return;
    }

    const newCart = [...(state.addToCart || []), data];

    setState((prev) => ({
      ...prev,
      addToCart: newCart,
    }));

    localStorage.setItem("addToCart", JSON.stringify(newCart));
  };

  const onRemoveCard = (id) => {
    const filterCard = state?.addToCart?.filter((card) => card?.id !== id);
    setState((prev) => ({
      ...prev,
      addToCart: filterCard,
    }));

    localStorage.setItem("addToCart", JSON.stringify(filterCard));
  };

  const onSetUser = (user = {}) => {
    setState((prev) => ({
      ...prev,
      user: user,
    }));
  };
  const onQuantityChange = (id, action) => {
    const updateQuantity = state?.addToCart?.map((product) =>
      product?.id === id
        ? {
            ...product,
            quantity:
              action === "increase"
                ? product.quantity + 1
                : Math.max(product.quantity - 1, 1),
          }
        : product
    );

    setState((prevState) => ({
      ...prevState,
      addToCart: updateQuantity,
    }));

    localStorage.setItem("addToCart", JSON.stringify(updateQuantity));
  };

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        onLanguageChange,
        onAddToCart,
        onRemoveCard,
        onQuantityChange,
        onSetUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
