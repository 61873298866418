import { useEffect, useState } from "react";
import API from "./API";

const useGetApi = (arg) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!arg?.url) {
      setIsLoading(false);
      setError("Invalid API URL");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await API.get(arg.url);
        setData(response?.data?.data);
      } catch (err) {
        setError(err?.response?.data?.message || err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [arg?.url]);

  return { data, isLoading, error };
};

export default useGetApi;
