import React from "react";
import { useAppContext } from "../context/AppContext";

export default function Counter({ qtn, id }) {
  const { onQuantityChange } = useAppContext();

  let [count, setCount] = React.useState(qtn);

  const increment = () => {
    setCount(count + 1);
    onQuantityChange(id, "increase");
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
      onQuantityChange(id, "decrease");
    }
  };

  return (
    <>
      <div className="qty-icons">
        <button
          onClick={() => decrement()}
          className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white minus"
        >
          -
        </button>
        <input
          min="0"
          name="quantity"
          value={count}
          type="number"
          readOnly
          className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white pointer-events-none w-16 ps-4 quantity mx-1"
        />
        <button
          onClick={() => increment()}
          className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white plus"
        >
          +
        </button>
      </div>
    </>
  );
}
