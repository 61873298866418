import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

export default function ProductDetail({ data }) {
  const {
    state: { lang },
  } = useAppContext();
  let [count, setCount] = useState(0);

  const increments = () => {
    setCount(count + 1);
  };
  const decrements = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  return (
    <div className="sticky top-20">
      <h5 className="text-2xl font-semibold">{data?.title?.[lang]}</h5>
      <div className="mt-2">
        <span className="text-slate-400 font-semibold me-1">
          ${data?.price}
          {/* <del className="text-red-600">$21USD</del> */}
        </span>

        {/* <ul className="list-none inline-block text-orange-400">
          <li className="inline">
            <i className="mdi mdi-star text-lg"></i>
          </li>
          <li className="inline">
            <i className="mdi mdi-star text-lg"></i>
          </li>
          <li className="inline">
            <i className="mdi mdi-star text-lg"></i>
          </li>
          <li className="inline">
            <i className="mdi mdi-star text-lg"></i>
          </li>
          <li className="inline">
            <i className="mdi mdi-star text-lg"></i>
          </li>
          <li className="inline text-slate-400 font-semibold">4.8 (45)</li>
        </ul> */}
      </div>

      <div className="mt-4">
        <h5 className="text-lg font-semibold">Overview :</h5>
        <p className="text-slate-400 mt-2">{data?.description?.[lang]}</p>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">
        {/* <div className="flex items-center">
          <h5 className="text-lg font-semibold me-2">Size:</h5>
          <div className="space-x-1">
            <Link
              to=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white"
            >
              S
            </Link>
            <Link
              to=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white"
            >
              M
            </Link>
            <Link
              to=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white"
            >
              L
            </Link>
            <Link
              to=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white"
            >
              XL
            </Link>
          </div>
        </div> */}

        <div className="flex items-center">
          <h5 className="text-lg font-semibold me-2">Quantity:</h5>
          <div className="qty-icons ms-3 space-x-0.5">
            <button
              onClick={() => decrements()}
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white minus"
            >
              -
            </button>
            <input
              min="0"
              name="quantity"
              value={count}
              onChange={() => {}}
              type="number"
              className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
            />
            <button
              onClick={() => increments()}
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white plus"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4 space-x-1">
        <Link
          to=""
          className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-green-500 text-white rounded-md mt-2"
        >
          Shop Now
        </Link>
        <Link
          to=""
          className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white mt-2"
        >
          Add to Cart
        </Link>
      </div>
    </div>
  );
}
