import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";

const ShopCard = ({ item }) => {
  const {
    state: { lang },
    onAddToCart,
  } = useAppContext();

  return (
    <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
        <img
          src={item?.coverImgUrl}
          className="group-hover:scale-110 duration-500 h-[300px] mx-auto"
          alt=""
        />

        <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
          <button
            onClick={() => onAddToCart(item)}
            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
          >
            Add to Cart
          </button>
        </div>

        {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                      <li>
                        <Link
                          to="#"
                          className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                        >
                          <FiHeart className="size-4"></FiHeart>
                        </Link>
                      </li>
                      <li className="mt-1 ms-0">
                        <Link
                          to="/shop-item-detail"
                          className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                        >
                          <FiEye className="size-4"></FiEye>
                        </Link>
                      </li>
                      <li className="mt-1 ms-0">
                        <Link
                          to="#"
                          className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                        >
                          <FiBookmark className="size-4"></FiBookmark>
                        </Link>
                      </li>
                    </ul> */}

        {/* <ul className="list-none absolute top-[10px] start-4">
                      {item.offer === true && (
                        <li>
                          <Link
                            to="#"
                            className="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                          >
                            {item.tag}
                          </Link>
                        </li>
                      )}
                      {item.tag === "New" && (
                        <li>
                          <Link
                            to="#"
                            className="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                          >
                            {item.tag}
                          </Link>
                        </li>
                      )}
                      {item.tag === "Featured" && (
                        <li>
                          <Link
                            to="#"
                            className="bg-emerald-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                          >
                            {item.tag}
                          </Link>
                        </li>
                      )}
                    </ul> */}
      </div>

      <div className="mt-4">
        <Link
          to={`/product-detail/${item?._id}`}
          className="hover:text-green-500 text-lg font-medium"
        >
          {item?.title?.[lang]}
        </Link>
        <div className="flex justify-between items-center mt-1">
          <p>
            (﷼) {item?.price}{" "}
            {/* <del className="text-slate-400">{item.amount}</del> */}
          </p>
          {/* <ul className="font-medium text-amber-400 list-none">
                        <li className="inline">
                          <i className="mdi mdi-star"></i>
                        </li>
                        <li className="inline">
                          <i className="mdi mdi-star"></i>
                        </li>
                        <li className="inline">
                          <i className="mdi mdi-star"></i>
                        </li>
                        <li className="inline">
                          <i className="mdi mdi-star"></i>
                        </li>
                        <li className="inline">
                          <i className="mdi mdi-star"></i>
                        </li>
                      </ul> */}
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
