import axios from "axios";
import { baseUrl, loginURL, logOutURL, paymentBaseUrl } from "../proxy";

export const API = axios.create({
  baseURL: baseUrl,
});

API.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token_link_card");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window !== "undefined") {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token_link_card");
        window.location.replace(loginURL);
      }
    }
    return Promise.reject(error);
  }
);

//payment

export const PAYMENT_API = axios.create({
  baseURL: paymentBaseUrl,
});

PAYMENT_API.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token_link_card");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

PAYMENT_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window !== "undefined") {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token_link_card");
        window.location.replace(loginURL);
      }
    }
    return Promise.reject(error);
  }
);
// Logout function
export const logout = async (userId) => {
  try {
    // console.log('clicked register api')
    const response = await axios.post(`${baseUrl}/users/auth/logout`, {
      userId,
    });

    if (typeof window !== "undefined") {
      localStorage.removeItem("token_link_card");
      window.open(logOutURL, "_self")
    }

    return response;
  } catch (error) {
    if (typeof window !== "undefined") {
      localStorage.removeItem("token_link_card");
      window.open(logOutURL, "_self")
    }

    throw error;
  }
};

export const profileUpdate = async (data = {}) => {
  try {
    const response = await API.put(`/users/update-profile`, data);

    if (typeof window !== "undefined" && response?.data?.success) {
      localStorage.setItem("token_link_card", response?.data?.data?.token);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const shopCheckout = async (data = {}) => {
  try {
    const response = await API.post(`/users/update-profile`, data);

    if (typeof window !== "undefined" && response?.data?.success) {
      localStorage.setItem("token_link_card", response?.data?.data?.token);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPayment = async (data = {}) => {
    const response = await PAYMENT_API.post(`/payment/create-checkout`, data);
    return response;

}
export const fetchPaymentSession = async (sessionId) => {
    const response = await PAYMENT_API.get(`/payment/checkout/${sessionId}`);
    return response;

}
export const webHooksCalledPayment = async (data) => {
  if(data?.sessionId){
    const response = await PAYMENT_API.post(`/hooks/payment/${data?.sessionId}`, data);
    return response;

  }else{
    throw Error("Session invalid!")
  }
    
}