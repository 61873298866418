import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaGlobe, FaSortDown } from "react-icons/fa";
import { FiMoon, FiSun } from "../assets/icons/vander";
import { useAppContext } from "../context/AppContext";

const languages = [
    { code: "ar", name: "Arabic", flag: "🇸🇦" },
    { code: "en", name: "English", flag: "🇺🇸" },
];

export default function Switcher() {
    const store = useAppContext()
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(languages[1]); // Default to English
    const [elements, setElements] = useState([]);
    // const user = store?.state?.user

    useEffect(() => {
        const textNodes = [];
        const walk = document.createTreeWalker(
            document.body,
            NodeFilter.SHOW_TEXT,
            null,
            false
        );
        let node;
        while ((node = walk.nextNode())) {
            if (node.textContent.trim() !== "") {
                textNodes.push(node);
            }
        }
        setElements(textNodes);
    }, []);

    const translateText = async (text, targetLang) => {
        try {
            const response = await axios.get(
                `https://api.mymemory.translated.net/get?q=${encodeURIComponent(
                    text
                )}&langpair=en|${targetLang}`
            );
            return response.data.responseData.translatedText;
        } catch (error) {
            //   console.error('Translation error:', error);
            return text;
        }
    };

    const handleLanguageSelect = async (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        // localStorage.setItem('switchLanguage', language?.code);
        localStorage.setItem('lang', language?.code);
        store.setState((prev) => ({
            ...prev,
            lang: language?.code,
        }));

        if (language.code !== "en") {
            for (let element of elements) {
                const originalText = element.textContent;
                const translatedText = await translateText(originalText, language.code);
                element.textContent = translatedText;
            }
        } else {
            // Reset to original English text
            window.location.reload();
        }
    };

    // rtl / ltr

    let htmlTag = document.getElementsByTagName("html")[0];
    const changeTheme = (e) => {
        if (htmlTag.className.includes("dark")) {
            htmlTag.className = "light";
        } else {
            htmlTag.className = "dark";
        }
    };

    useEffect(() => {
        const handleUseLang = async() => {
            // setSelectedLanguage({ code: store?.state?.lang });
            if (store?.state?.lang !== "en") {
                for (let element of elements) {
                    const originalText = element.textContent;
                    const translatedText = await translateText(originalText, store?.state?.lang);
                    element.textContent = translatedText;
                }
            } else {
                window.location.reload();
            }
        }

        if (store?.state?.lang === "en") {
            htmlTag.dir = "ltr";
        } else {
            handleUseLang();
            htmlTag.dir = "rtl";
        }
        console.log(store?.state?.lang);
    }, [store?.state?.lang])

    // useEffect(() => {
    //     const switchLanguage = localStorage.getItem('switchLanguage');
    //     if(!switchLanguage){
    //         localStorage.setItem('switchLanguage', 'en')
    //     }else{
    //         handleLanguageSelect({code: switchLanguage})
    //     }

    //     console.log(switchLanguage);
    // }, [handleLanguageSelect])

    useEffect(() => {
        if (selectedLanguage?.code === "en") {
            htmlTag.dir = "ltr";
        } else {
            htmlTag.dir = "rtl";
        }
    }, [selectedLanguage]);

    // const modeChange = () => {
    //     const switcherRtl = document.getElementById("switchRtl")
    //     if (switcherRtl.innerText === "LTR") {
    //         htmlTag.dir = "ltr"
    //     }
    //     else {
    //         htmlTag.dir = "rtl"
    //     }
    // }
    return (
        <>
            <div className="fixed top-1/4 -left-2 z-50">
                <span className="relative inline-block rotate-90">
                    <input
                        type="checkbox"
                        className="checkbox opacity-0 absolute"
                        id="chk"
                        onChange={(e) => changeTheme(e)}
                    />
                    <label
                        className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
                        htmlFor="chk"
                    >
                        <FiMoon className="w-[18px] h-[18px] text-yellow-500"></FiMoon>
                        <FiSun className="w-[18px] h-[18px] text-yellow-500"></FiSun>
                        <span className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
                    </label>
                </span>
            </div>

            {/* <div className="fixed top-[40%] -left-3 z-50">
            <Link to="" id="switchRtl" onClick={()=>modeChange()}>
                <span className="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold rtl:block ltr:hidden" >LTR</span>
                <span className="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-semibold ltr:block rtl:hidden">RTL</span>
            </Link>
        </div> */}

            {/* language changer */}
            <div className="inline-block text-left fixed top-[40%] left-2 z-50">
                <div>
                    <button
                        type="button"
                        className="inline-flex items-center justify-center w-full rounded-md border-0 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 gap-3.5"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <FaGlobe
                            className="mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        {selectedLanguage.name}
                        <FaSortDown
                            className="ml-2 -mt-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                {isOpen && (
                    <div className="origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {languages.map((language) => (
                                <button
                                    key={language.code}
                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                    onClick={() => handleLanguageSelect(language)}
                                >
                                    <span className="mr-2">{language.flag}</span>
                                    {language.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
